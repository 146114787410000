.swiper {
  width: clamp(240px, 80%, 860px);
  height: 100%;
	
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.swiper-pagination{
	visibility: hidden;
}

.autoplay-progress {
	visibility: hidden;
  position: absolute;
  right: 0px;
  bottom: 16px;
  z-index: 10;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight:100;
  color: var(--swiper-theme-color);
}

.autoplay-progress svg {
  --progress: 0;
  position: absolute;
  left: 0;
  top: 0px;
  z-index: 10;
  width: 100%;
  height: 100%;
  stroke-width: 4px;
  stroke: var(--swiper-theme-color);
  fill: none;
  stroke-dashoffset: calc(125.6px * (1 - var(--progress)));
  stroke-dasharray: 125.6;
  transform: rotate(-90deg);
}

.mySwiper{
	color: #000;
	margin-bottom: 40px;
	.mySlider{
		display: flex;
		box-sizing: border-box;
		padding: 14px;
		position: relative;
		font-size: clamp(1rem, 2.5vw, 2rem);
		height: clamp(240px, 400px, 360px);
		p{
			background-color: rgba(0,0,0, 0.1);
			color: #fff;
			border-bottom: 3px solid #fff;
			padding: 20px;
			border-top-left-radius: 6px;
			border-top-right-radius: 6px;
		}
		img{
			position: absolute;
			object-position: center bottom;
			filter: blur(2px);
			bottom: 0;
			border-radius: 10px;
			right: 0;
			z-index: -1;
			width: 100%;
			
		}
	}
}