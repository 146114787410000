@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');

* {
    margin: 0;
    padding: 0;
}

li {
    list-style-type: none;
}

body {
    background: #e3f3fc;
    font-family: 'Noto Sans', sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-variation-settings: 'wdth' 100;
}

.wrapper {
    max-width: 1200px;
    margin: 20px auto;
    background: #e3f3fc;
}

.styleBlock {
    background: #fff;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 20px;
    box-shadow: 0 0 8px 3px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}
.buttonCalc {
    width: 25px;
    height: 25px;
    margin-bottom: 10px;
    font-size: max(10px, 1vw);
    border-radius: 6px;
    border: none;
    box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.2);

    &:active {
        background-color: #cecece;
        box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.3) inset;
    }
}

.header {
    position: relative;
    font-size: clamp(0.7rem, 1vw, 2rem);
    .buttonCont {
        /* position: absolute;
		top:25%;
		right: 40px; */
        @media (min-width: 625px) {
            display: none;
        }
    }
    .topLine {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;

        .name {
            flex-grow: 1;
        }

        .contacts {
            ul {
                li {
                    display: flex;
                    align-items: center;
                    justify-content: end;
                    gap: 5px;
                    margin-bottom: 4px;
                }
            }
            @media (max-width: 625px) {
                display: none;
            }
        }
    }
    address {
        width: max-content;
        font-size: 14px;
        margin-top: 6px;
        border-bottom: 2px solid #e3f3fc;
        @media (max-width: 625px) {
            display: none;
        }
    }
}

.home {
}

.cards {
    @media (max-width: 900px) {
        max-width: 98%;
    }
    display: flex;
    flex-direction: column;
    max-width: 80%;
    margin: 0 auto;
    gap: 20px;
    .card {
        @media (max-width: 900px) {
            flex-direction: column;
            height: 340px;
            width: 100%;
        }
        display: flex;
        flex-direction: row;
        gap: 8px;
        height: 180px;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        box-shadow: 3px 3px 6px 2px rgba(0, 0, 0, 0.1);
        overflow: hidden;
        img {
            @media (max-width: 900px) {
                width: 100%;
                height: 100px;
            }
            width: 30%;
            object-fit: cover;
        }
        .cardDesc {
            @media (max-width: 900px) {
                font-size: 12px;
                padding-left: 15px;
            }
            width: 40%;
            padding-top: 10px;
            h3 {
                font-weight: 400;
                margin-bottom: 12px;
            }
            p {
                font-weight: 300;
                background: #e3f3fc;
                width: fit-content;
                padding: 4px 8px;
                border-radius: 6px;
            }
        }
        .cardCalc {
            @media (max-width: 900px) {
                font-size: 14px;
                padding-left: 15px;
            }
            padding-top: 10px;
            p {
                margin-bottom: 12px;
            }
            span {
                padding: 0 8px;
            }
            .buttonDis {
                box-shadow: none;
            }
        }
    }
}

.headerBlock {
    margin-bottom: 30px;
    .headerBlockTop {
        display: flex;
        flex-direction: row;

        h2 {
            flex-grow: 1;
            height: max-content;
            align-self: flex-end;
        }
    }
}

footer {
    span {
        display: block;
        margin: 0 auto;
        width: max-content;
    }
}

.rightContact {
    position: absolute;
    width: 100%;
    height: 260px;
    right: 0;
    box-sizing: border-box;
    padding: 15px;
    background-color: #e3f3fc;
    z-index: 2;
    .close {
        position: absolute;
        right: 20px;
    }
    .contacts {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        li {
            margin-bottom: 20px;
        }
    }
}
.buttonStyle {
    padding: 10px 20px; /* Поля вокруг текста */
    background-color: #e3f3fc; /* Цвет фона */
    color: #000; /* Цвет текста */
    border: 1px solid #000; /* Рамка вокруг */
    border-radius: 10px; /* Скругления */
    font-size: 20px;
    cursor: pointer;
    @media (max-width: 900px) {
        right: 10px;
        padding: 10px 15px;
    }
}
.buttonNotActive {
    padding: 10px 20px; /* Поля вокруг текста */
    background-color: #e3f3fc; /* Цвет фона */
    color: #ccc; /* Цвет текста */
    border: 1px solid #000; /* Рамка вокруг */
    border-radius: 10px; /* Скругления */
    font-size: 20px;
    cursor: default;
    @media (max-width: 900px) {
        right: 10px;
        padding: 10px 15px;
    }
}
.order-button {
    position: fixed;
    bottom: 20px; /* Отступ от низа */
    right: 20px; /* Отступ от правого края */
    z-index: 2;
}

.deliveryForm {
    max-width: 480px;
    width: 100%;
    margin: 40px auto 40px;
    .selectProduct {
        margin-top: 16px;
        .selectProductItem {
            border-bottom: 1px solid #e3f3fc;
            .wrapperButtonCalc {
                margin-top: 8px;
            }
        }
    }
    p {
        white-space: pre-line;
    }
    form {
        display: flex;
        flex-direction: column;
        row-gap: 16px;
        padding: 16px;
        input {
            border: none;
            background-color: #e3f3fc;
            padding: 8px;
            border-radius: 12px;
        }
    }
}
.wrapperButtonCalc {
    span {
        padding: 0 8px;
    }
}

.review {
    .reviewItem {
        @media (max-width: 915px) {
            flex-direction: column;
        }
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 16px;
        border-bottom: 1px solid #e3f3fc;
        -webkit-box-shadow: 0px -8px 9px -6px rgba(34, 60, 80, 0.23) inset;
        -moz-box-shadow: 0px -8px 9px -6px rgba(34, 60, 80, 0.23) inset;
        box-shadow: 0px -8px 9px -6px rgba(34, 60, 80, 0.23) inset;
        padding-bottom: 8px;
        margin-bottom: 24px;
        .reviewItemImg {
        }
        .reviewItemText {
            h3 {
                margin-bottom: 8px;
            }
            .reviewItemText__text {
                position: relative;
                padding: 0 20px 0 28px;
                p {
                    color: #173c78;
                    font-style: oblique;
                }
                &::before {
                    position: absolute;
                    content: '';
                    background-image: url('../public/svg/quotes-before.svg');
                    background-size: contain;
                    background-repeat: no-repeat;
                    top: -4px;
                    left: 0;
                    width: 28px;
                    height: 20px;
                }
                &::after {
                    position: absolute;
                    content: '';
                    background-image: url('../public/svg/quotes-after.svg');
                    background-size: contain;
                    background-repeat: no-repeat;
                    bottom: 0;
                    right: 0;
                    width: 28px;
                    height: 20px;
                }
            }
        }
    }
}
